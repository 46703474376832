import config from './$RESOURCES';

/** return a config property, or undefined if it does not exist
 *
 * @param name string
 * @returns {*}
 */

export function getProperty (name) {
    return window.SERVER_DATA[name] || config[name];
}

export function initializeConfig () {
    return config.initializeConfig();
}

// application reducer

import { STORE_FORM_DATA } from 'mpo-atomic-ui/forms/actions';
import { addressLoqateResponse } from './initialState';
import { applicantStructure } from '../common/utils';
import _cloneDeep from 'lodash/cloneDeep';

const SET_SERVICE_TYPE = 'SET_SERVICE_TYPE';
const SET_ERROR_RESPONSE = 'SET_ERROR_RESPONSE';
const SET_PERSONAL_DETAILS = 'SET_PERSONAL_DETAILS';
const SHOW_TIMEOUT_POPUP = 'SHOW_TIMEOUT_POPUP';
const HIDE_TIMEOUT_POPUP = 'HIDE_TIMEOUT_POPUP';
const SESSION_TIMEOUT = 'SESSION_TIMEOUT';
const SET_SPINNER = 'SET_SPINNER';
const SET_CREATE_APP_RESPONSE = 'SET_CREATE_APP_RESPONSE';
const SET_LOQATE_RESPONSE = 'SET_LOQATE_RESPONSE';
const SET_ADDRESS_LOQATE_RESPONSE = 'SET_ADDRESS_LOQATE_RESPONSE';
const SET_LOQATE_ERROR_RESPONSE = 'SET_LOQATE_ERROR_RESPONSE';
const SET_LOQATE_VALIDATION_ERROR = 'SET_LOQATE_VALIDATION_ERROR';
const SET_DIP_RESULT_RESPONSE = 'SET_DIP_RESULT_RESPONSE';
const UPDATE_TOKEN = 'UPDATE_TOKEN';
const SET_APPLICANT_STATE = 'SET_APPLICANT_STATE';
const STORE_APPLICANT_DATA = 'STORE_APPLICANT_DATA';
const DISABLE_BUTTON = 'DISABLE_BUTTON';
const SET_BUYER_TYPE = 'SET_BUYER_TYPE';
const SET_LOCALE = 'SET_LOCALE';
const SET_BUREAU_AUTH_QUERY_DATA = 'SET_BUREAU_AUTH_QUERY_DATA';

const serverData = window.SERVER_DATA;

const init = {
    addressLoqateResponse: addressLoqateResponse,
    loqateResponse: addressLoqateResponse,
    loqateSearchErrorFlag: {
        searchBox: false,
        previousSearchBox: false
    },
    loqateValidationFlag: {
        searchBox: false,
        previousSearchBox: false
    },
    disableButton: false,
    ...(serverData.csrf ? { csrf: serverData.csrf } : {}),
    ...(serverData.requestTrust ? { requestTrust: serverData.requestTrust } : {}),
};

export default function reducer(state = init, action) {

    switch (action.type) {

        case UPDATE_TOKEN:
            return {
                ...state,
                csrf: action.token.csrf,
                requestTrust: action.token.requestTrust
            };

        case SET_SERVICE_TYPE:
            return {
                ...state,
                serviceType: action.serviceType
            };

        case SET_PERSONAL_DETAILS:
            return {
                ...state,
                personalDetails: action.personalDetails
            };

        case SET_ERROR_RESPONSE:
            return {
                coreData: state.coreData,
                errorResponse: action.errorResponse
            };

        case SHOW_TIMEOUT_POPUP: {
            const a = document.activeElement || {};
            return {
                ...state,
                showTimeoutPopup: true,
                previousActiveElement: a.id
            };
        }

        case HIDE_TIMEOUT_POPUP:
            return {
                ...state,
                showTimeoutPopup: false
            };

        case SESSION_TIMEOUT:  // wipe out state
            return {
                coreData: state.coreData,
                errorResponse: {
                    sessionTimeout: true
                }
            };

        case SET_SPINNER: {
            const { spinnerStatus, spinnerDetails } = action;
            return {
                ...state,
                spinnerStatus,
                spinnerDetails
            };
        }

        case SET_CREATE_APP_RESPONSE:
            return {
                ...state,
                ...action.responseData,
                spinnerStatus: null
            };

        case SET_DIP_RESULT_RESPONSE:
            return {
                ...state,
                dipResult: action.responseData,
                spinnerStatus: false
            };

        case STORE_FORM_DATA: {
            const { formName, formData } = action;
            return {
                ...state,
                [formName]: formData
            };
        }

        case STORE_APPLICANT_DATA: {
            const { formName, formData, sequenceIdentifier } = action;
            return {
                ...state,
                applicant: {
                    ...state.applicant, [sequenceIdentifier]: {
                        ...state.applicant[sequenceIdentifier],
                        ...state.applicant[sequenceIdentifier][formName],
                        [formName]: _cloneDeep(formData)
                    }
                }
            };
        }

        case SET_LOQATE_RESPONSE: {
            const { searchBox, loqateResponse, sequenceIdentifier } = action;
            return {
                ...state,
                loqateResponse: {
                    ...state.loqateResponse,
                    [sequenceIdentifier]: {
                        ...state.loqateResponse[sequenceIdentifier],
                        ...state.loqateResponse[sequenceIdentifier][searchBox],
                        [searchBox]: loqateResponse
                    }
                },
                spinnerStatus: null
            };
        }

        case SET_ADDRESS_LOQATE_RESPONSE: {
            const { searchBox, addressLoqateResponse, sequenceIdentifier } = action;
            return {
                ...state,
                addressLoqateResponse: {
                    ...state.addressLoqateResponse,
                    [sequenceIdentifier]: {
                        ...state.addressLoqateResponse[sequenceIdentifier],
                        ...state.addressLoqateResponse[sequenceIdentifier][searchBox],
                        [searchBox]: addressLoqateResponse
                    }
                },
                spinnerStatus: null
            };
        }

        case SET_LOQATE_ERROR_RESPONSE:
            return {
                ...state,
                loqateSearchErrorFlag: {
                    ...state.loqateSearchErrorFlag,
                    [action.addressType]: action.errorFlag
                }
            }

        case SET_LOQATE_VALIDATION_ERROR:
            return {
                ...state,
                loqateValidationFlag: {
                    ...state.loqateValidationFlag,
                    [action.addressType]: action.errorFlag
                }
            }

        case SET_APPLICANT_STATE: {
            const { numberOfApplicants } = action;
            return {
                ...state,
                applicant: applicantStructure(state.applicant, numberOfApplicants),
                numberOfApplicants: numberOfApplicants
            }
        }

        case DISABLE_BUTTON:
            return {
                ...state,
                disableButton: action.buttonStatus
            }

        case SET_BUYER_TYPE: {
            const { buyerTypeDesc } = action;
            return {
                ...state,
                buyerDetails: buyerTypeDesc
            }
        }

        case SET_LOCALE: {
            const { language } = action;
            return {
                ...state,
                preferredLocale: language
            };
        }

        case SET_BUREAU_AUTH_QUERY_DATA:
            return {
                ...state,
                bureauAuthData: action.responseData,
                spinnerStatus: false
            };

        default:
            return state;
    }
}

export function updateToken(token) {
    return {
        type: UPDATE_TOKEN,
        token
    }
}

export function setServiceType(serviceType) {
    return {
        type: SET_SERVICE_TYPE,
        serviceType
    };
}

export function setPersonalDetails(personalDetails) {
    return {
        type: SET_PERSONAL_DETAILS,
        personalDetails
    };
}

export function setErrorResponse(errorResponse) {
    return {
        type: SET_ERROR_RESPONSE,
        errorResponse
    };
}

export function showTimeoutPopup() {
    return {
        type: SHOW_TIMEOUT_POPUP
    };
}

export function hideTimeoutPopup() {
    return {
        type: HIDE_TIMEOUT_POPUP
    };
}

export function setSessionTimedOut() {
    return {
        type: SESSION_TIMEOUT
    };
}

export function setSpinner(spinnerStatus, spinnerDetails = null) {
    return {
        type: SET_SPINNER,
        spinnerStatus,
        spinnerDetails
    };
}

export function setCreateAppResponse(responseData) {
    return {
        type: SET_CREATE_APP_RESPONSE,
        responseData
    };
}

export function setLoqateResponse(loqateResponse, searchBox, sequenceIdentifier) {
    return {
        type: SET_LOQATE_RESPONSE,
        loqateResponse,
        searchBox,
        sequenceIdentifier
    };
}

export function clearAddressResponse(addressLoqateResponse, searchBox, sequenceIdentifier) {
    return {
        type: SET_ADDRESS_LOQATE_RESPONSE,
        addressLoqateResponse,
        searchBox,
        sequenceIdentifier
    };
}

export function setAddressLoqateResponse(addressLoqateResponse, searchBox, sequenceIdentifier) {
    return {
        type: SET_ADDRESS_LOQATE_RESPONSE,
        addressLoqateResponse,
        searchBox,
        sequenceIdentifier
    };
}

export function setDipResultResponse(responseData) {
    return {
        type: SET_DIP_RESULT_RESPONSE,
        responseData
    };
}

export function setLoqateError(errorFlag, addressType) {
    return {
        type: SET_LOQATE_ERROR_RESPONSE,
        errorFlag,
        addressType
    };
}

export function setLoqateValidationError(errorFlag, addressType) {
    return {
        type: SET_LOQATE_VALIDATION_ERROR,
        errorFlag,
        addressType
    };
}

export function setApplicantState(numberOfApplicants) {
    return {
        type: SET_APPLICANT_STATE,
        numberOfApplicants
    };
}

export function disableButton(buttonStatus) {
    return {
        type: DISABLE_BUTTON,
        buttonStatus
    };
}

export function setBuyerType(buyerTypeDesc) {
    return {
        type: SET_BUYER_TYPE,
        buyerTypeDesc
    };
}

export function setLocale(language) {
    return {
        type: SET_LOCALE,
        language
    };
}

export function setBureauAuthenticationQueryResponse(responseData) {
    return {
        type: SET_BUREAU_AUTH_QUERY_DATA,
        responseData
    };
}

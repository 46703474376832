import analytics from './analytics.json';

/** default, common client configuration settings */
const config = {
    invalidQuoteReasons: ['POLICY'],

    analytics: analytics,
    initializeConfig,
    termOfMortgageRange: ['1', '35'],
    childDependentRange: ['0', '4'],
    adultDependentRange: ['0', '4'],
    CURRENCY_SYMBOL: "£",
    COUNTRY_CODE: 'GB',
    AMOUNT_CURRENCY: "GBP",
    HOUSE_NUMBER_REGEX: /(^$)|^(?! *$)[a-zA-Z0-9 !"#$%&'()*+-.,/:;=?@_&]*$/,
    ADDRESS_LINES_REGEX: /(^$)|^(?! *$)[a-zA-Z0-9 ]*$/,
    POST_CODE_REGEX: /(^$)|^((([a-zA-Z]{1,2}[0-9]{1,2}?|[a-zA-Z]{1,2}[0-9]{1}[a-zA-Z]{1}?) [0-9]{1}[a-zA-Z]{2}))$/,
    INPUT_REGEX: /^(?!.*[\\']{2})[A-Za-z-'. ]*$/,
    REQUIRED_YEARS: 1,
    DEFAULT_MONTHS: '0',
    YEARS_RANGE: [0, 29],
    MONTHS_RANGE: [0, 11],
    ADDRESS_MAX_LENGTH: 33,
    POST_CODE_MAX_LENGTH: 8,
    DECISION_CODE_1: 'A1_1',
    DECISION_CODE_3: 'A1_3',
    DIP_SUCCESS_CODES: ['A1_1', 'A1_2', 'A1_3'],
    DIP_FAILURE_CODES: ['R1_1', 'R1_2'],
    LOQATE_COUNTRY_CODE: 'GBR',
    DATE_FORMAT: "YYYY-MM-DD",
    MIN_LENGTH : 10,
    EMAIL_REGEX : /^$|^(((?!\.)(?!.*?\.\.)[\w!#$%'*+/?_&-])+(\.[\w!#$%'*+/?_&-]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    HYPHENS_REGEX : new RegExp('\\-{2,}'),
    MOBILE_REGEX : /^[^)+ (-]+$/,
    incomeDoorhangersList : [
        'grossSalary',
        'profit',
        'pension',
        'investmetIncome',
        'dividendsIncome',
        'stateBenefitIncome',
        'buyToLetIncome'
    ],
    expenditureDoorhangersList : [
        'rentCharge',
        'childMaintenanceCosts',
        'studentLoanRepayments',
        'educationFees',
        'committedInvestments',
        'essentialTravel',
        'buyToLetCosts',
        'adultSocialCareCosts'
    ]
};

export default config;
/** this is called during App initialize */

function initializeConfig() { 
    //initial function
}

export const mappingLoqate = (address) => {
    return {
        line1: [address.SubBuilding, `${address.BuildingName} ${address.BuildingNumber}`].filter(val => val).join(', ').trim(),
        line2: address.Company,
        line3: address.Street,
        line4: address.District,
        line5: address.City,
        line6: address.Province,
        postCode: address.PostalCode
    }
};

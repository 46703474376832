import 'react-app-polyfill/ie11'; // has to be first
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'mpo-ui-common-utils/dist/polyfills/Array_find'; // polyfill for IE11
import 'core-js/es';
// this application
import App from './App';
import store from './state';

const render = (Root) => {
    ReactDOM.render(
        <Provider store={store}><Root/></Provider>,
        document.getElementById('root')
    );
};

App.initialize( store, () => render(App) );

// hot loading - if using react-scripts this code will not exist in production
// it's not possible to get coverage on this code as NODE_ENV is set to 'test'

/* istanbul ignore next */
if (process.env.NODE_ENV==='development') {
    if (module.hot) {
        module.hot.accept('./App', () => {
            const NextApp = require('./App').default;
            render(NextApp);
        });
    }
}

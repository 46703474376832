import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'mpo-atomic-ui/theme';
// actions
import loadLanguage from './actions/loadLanguage/$RESOURCES';
// routing
import ApplicationRouter from './routing';
// configuration
import { initializeConfig } from './config';
// browser
import { setOnBeforeUnload } from 'mpo-ui-common-utils/dist/browserHelpers';
// force css to be imported for these components before App.css
import 'mpo-atomic-ui/atoms/BrandBar';
import 'mpo-atomic-ui/atoms/MessageBox';
// common application styles
import '@piggybank/core/lib/styles.css';
import '@piggybank/form/lib/styles.css';

import './App.css';

/** the base application component */

export class App extends React.Component {

    static propTypes = {
        coreData: PropTypes.shape({})  // data that should force a redraw, ie language
    };

    render () {
        return <ApplicationRouter/>;
    }
}

/** called from index before rendering */

App.initialize = (store, render) => {

    initializeConfig();

    const isErrorPage = window.location.pathname === '/error';

    const promises = [loadLanguage()];
    // if we are on the error page, do not load loans
    if (!isErrorPage) {
        setOnBeforeUnload();
    }

    return Promise.all(promises)
        .then(() => {
            render();
        });
};

/** connect app to redux */

function mapStateToProps (state) {
    const { coreData } = state.globalState;

    return {
        coreData
    };
}

export default connect(mapStateToProps, {})(App);

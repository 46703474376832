export const addressInitialValuesForRedux = {
    searchBox: '',
    previousSearchBox: '',
    currentAddress: {
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        line5: '',
        line6: '',
        province: '',
        postCode: ''
    },
    previousAddress: {
        line1: '',
        line2: '',
        line3: '',
        line4: '',
        line5: '',
        line6: '',
        province: '',
        postCode: ''
    },
    yearsAndMonths: {
        years: '',
        months: '0'
    }
};

export const addressLoqateResponse = {
    1:{
        searchBox: {
            Items: []
        },
        previousSearchBox: {
            Items: []
        }
    },
    2: {
        searchBox: {
            Items: []
        },
        previousSearchBox: {
            Items: []
        }
    }
};

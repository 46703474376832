export const ROOT_ROUTE = '/';
export const PERSONAL_DETAILS_ROUTE = '/personal-details';
export const PRODUCT_DETAILS_ROUTE = '/product-details';
export const DEPENDENT_DETAILS_ROUTE = '/dependent-details';
export const TERMS_AND_CONDITIONS_ROUTE = '/terms-conditions';
export const EXPENDITURE_DETAILS_ROUTE = '/expenditure-details';
export const EMPLOYMENT_DETAILS_ROUTE = '/employment-details';
export const INCOME_DETAILS_ROUTE = '/income-details';
export const ADDRESS_DETAILS_ROUTE = '/address-details';
export const CONTACT_DETAILS_ROUTE = '/contact-details';
export const DIP_RESULT_ROUTE = '/dip-result';
export const ERROR_PAGE = '/error';
export const ERROR_PAGE_DECISION_BUREAU = '/dip-error';
export const TIMEOUT_PAGE = '/timeout';
export const BUREAU_AUTH_PAGE = '/bureau-authentication';
export const BUREAU_FAILURE = '/bureau-error';
export const SUMMARY_DETAILS = '/summary-details';
import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { createBrowserHistory } from 'history';
// this app
import styles from '../components/SplitViewPage/page.module.css';
import { map } from 'lodash';
import {
    Loading
} from '@piggybank/core';

import { translate } from 'mpo-ui-common-utils/translate';
import * as routes from './routePath';

export const history = createBrowserHistory();

const ApplicationRouter = () => {
  const containers = map(routes, ({ path, component }, key) => <Route component={component} exact key={key} path={path}/>)
    return (
        <Router history={history}>
                <React.Suspense fallback={
                    <Loading getApplicationNode={() => document.getElementById('root')} show>
                        <div className={styles['bold']} id='loadingHeader'>
                            {translate('loadingText')}
                        </div>
                    </Loading>
                    }
                >
        <Switch>
            {containers}
          </Switch>
        </React.Suspense>
        </Router>
    );
};

export default ApplicationRouter;

import { cancelOnBeforeUnload } from 'mpo-ui-common-utils/browserHelpers';
import { getProperty } from '../config';
import { translateValues } from 'mpo-ui-common-utils/translate';

const appRoot = '/';

export const gotoHomePage = () => {
    cancelOnBeforeUnload();
    window.location.assign(translateValues('pwslandingUrl', { pwsLandingUrl: getProperty('pwsLandingUrl') }));
};

export const goToDipHomePage = () => {
    cancelOnBeforeUnload();
    window.location.assign(appRoot);
};

import _get from 'lodash/get';
import { addressInitialValuesForRedux } from '../../state/initialState';
import { translate } from 'mpo-ui-common-utils/translate';
import { goToDipHomePage } from '../../actions/gotoHomePage';
import { getProperty } from '../../config';
import { mappingLoqate } from './config/$RESOURCES';

export const getRange = ([min, max]) => {
    const range = [];
    for (let i = min; i <= max; i++) {
        range.push({
            label: `${i}`, value: `${i}`
        });
    }

    return range;
}

export const lessThanRequiredYears = ({
    years = '',
    months = ''
}) => {
    const requiredYears = getProperty('REQUIRED_YEARS');
    if ((months === '0' && years === '')) {
        return false;
    }
    if ((Number(years) * 12 + Number(months)) < (Number(requiredYears) * 12)) {
        return true;
    }

    return false;
}

export const convertLoqateAddressToInvolvedParty = (address) => mappingLoqate(address);

export const mapAddressDetails = (values, addressResponse, firstApplicantCurrentAddress, applicantDetails, sequenceIdentifier) => {

    if (!applicantDetails[sequenceIdentifier].currentManualAddressClicked && addressResponse.searchBox.Items.length > 0) {
        values.currentAddress = convertLoqateAddressToInvolvedParty(addressResponse.searchBox.Items[0]);
        values.currentAddressPafIndicator = '1';
        values.currentAddressDpsCode = addressResponse.searchBox.Items[0].Field1;
    } else if (!applicantDetails[sequenceIdentifier].currentManualAddressClicked && addressResponse.searchBox.Items.length === 0) {
        values.currentAddress = firstApplicantCurrentAddress;
        values.currentAddressPafIndicator = '0';
        values.currentAddressDpsCode = '';
    } else {
        values.currentAddressPafIndicator = '0';
        values.currentAddressDpsCode = '';
    }
    if (lessThanRequiredYears(values.yearsAndMonths)) {
        if (!applicantDetails[sequenceIdentifier].previousManualAddressClicked && addressResponse.previousSearchBox.Items[0]) {
            values.previousAddress = convertLoqateAddressToInvolvedParty(addressResponse.previousSearchBox.Items[0]);
            values.previousAddressPafIndicator = '1';
            values.previousAddressDpsCode = addressResponse.previousSearchBox.Items[0].Field1;
        } else {
            values.previousAddressPafIndicator = '0';
            values.previousAddressDpsCode = '';
        }
    }
    return values;
}

export const applicantStructure = (stateApplicantData, noOfApplicants) => {
    let applicantObject = {};
    const initialApplicant = {
        personalDetails: {
            title: '',
            otherTitle: '',
            firstName: '',
            middleName: '',
            surname: '',
            idType:'',
            idDetails:'',
            issuanceCountry:'',
            dateOfBirth: ''
        },
        addressDetails: addressInitialValuesForRedux,
        employmentDetails: {
            employmentStatus: ''
        },
        incomeDetails: {
            annualIncome: '',
            otherIncomeRadio: 'no',
            otherIncome: ''
        },
        expenditureDetails: {
            totalBalance: '',
            monthlyLoan: '',
            otherOutgoings: 'no',
            otherCommitments: ''
        }
    };
    for (let i = 1; i <= noOfApplicants; i++) {
        applicantObject = { ...applicantObject, ...{ [i]: _get(stateApplicantData, i, initialApplicant) } }
    }

    return applicantObject;
}

export const blockBrowserBackForwardActions = history => {
    history.block((location, action) => {
        if (action === 'POP') {
            if (window.confirm(translate('backAlertLabel'))) {
                goToDipHomePage();
            }
            else {
                return false;
            }
        }
        return true;
    });
}

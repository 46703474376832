import defaultConfig from '../default';
import analytics from './analytics.json';

/** default, common client configuration settings */
const config = {
    ...defaultConfig,
    analytics: analytics,
    termOfMortgageRange: [5, 35],
    CURRENCY_SYMBOL: "RM",
    AMOUNT_CURRENCY: "MYR",
    COUNTRY_CODE: 'MY',
    ID_DETAILS_REGEX: /(^$)|^[A-Z0-9]{6}-[A-Z0-9]{2}-[A-Z0-9]{4}$/,
    PASSPORT_REGEX: /(^$)|^[A-Z0-9]*$/,
    ADDRESS_MAX_LENGTH: 100,
    POST_CODE_MAX_LENGTH: 5,
    DECISION_CODE_1: 'AC',
    DECISION_CODE_2: 'RV',
    DIP_SUCCESS_CODES: ['AC', 'RV'],
    DIP_FAILURE_CODES: ['DL'],
    HOUSE_NUMBER_REGEX: /^[a-zA-Z0-9 !"#$%&'()_*+-.,/:;=?@_&]*$/,
    ADDRESS_LINES_REGEX: /^[a-zA-Z0-9 !"#$%&'()_*+-.,/:;=?@_&\\]*$/,
    POST_CODE_REGEX: /^[0-9]*$/,
    MIN_LENGTH : 9,
    expenditureDoorhangersList: [
        'mortgageLoan',
        'overdraft',
        'wealthLending',
    ],
    incomeDoorhangersList: [
        'grossSalary',
        'bonus',
        'overtime',
        'commission',
        'profitAfterTax'
    ]

}

export default config;
import { ROOT_URL } from '../../constants';
import { fetchLanguageJson, getUrlLanguage, defaultLanguage } from './common';

export default function loadLanguage(desired) {

    const lang = desired || getLanguage();

    const url = ROOT_URL + '/language/' + lang + '.json';  // ie '/utility/language/zh.json'

    const options = {
        method: 'GET',
        credentials: 'include'
    };

    return fetchLanguageJson(url, options, lang, loadLanguage);

}

function getLanguage() {
    const lang = defaultLanguage;
    return getUrlLanguage(lang);
}

import { setMessages } from 'mpo-ui-common-utils/translate';

export const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;

export function fetchLanguageJson(url, options, lang, loadLanguage) {
    return fetch(url, options)
        .then((response) => {
            if (response.status !== 200) {  // language load failed
                if (lang !== defaultLanguage) {           // retry using default language
                    window.loadLanguageError = true;
                    return loadLanguage(defaultLanguage);
                }
                return;
            }
            return response.json()
                .then((json) => {
                    setMessages(json);
                    return json;
                });
        })
        .catch(err => {
            console.error('LANGUAGE LOAD ERROR', err);  // eslint-disable-line no-console
            return err;
        });
}

export function getUrlLanguage(lang) {
    // Setting url language
    const search = window.location.search;
    if (search) {
        const pairs = search.substring(1).split('&');
        pairs.forEach((pair) => {
            const [code, value] = pair.split('=');
            if (code === 'lang') {
                lang = value;
            }
        });
    }

    return lang;
}

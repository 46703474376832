import * as route from "./routes";
import React from "react";

//export const ROOT_ROUTE = '/';

export const CustomerLandingPage = {
    path: route.ROOT_ROUTE,
    component: React.lazy(() => import("../components/LandingPage"))
  };

  export const PersonalDetailsPage = {
    path:`${route.PERSONAL_DETAILS_ROUTE}/:applicantNumber`,
    component: React.lazy(() => import("../pages/PersonalDetailsPage"))
  };

  export const ProductDetailsPage = {
    path: route.PRODUCT_DETAILS_ROUTE,
    component: React.lazy(() => import("../pages/ProductDetailsPage"))
  };

  export const TermsAndConditionsPage = {
    path: route.TERMS_AND_CONDITIONS_ROUTE,
    component: React.lazy(() => import("../pages/TermsAndConditionsPage"))
  };

  export const DependentDetailsPage = {
    path: route.DEPENDENT_DETAILS_ROUTE,
    component: React.lazy(() => import("../pages/DependentDetailsPage"))
  };

  export const ExpenditureDetailsPage = {
    path:`${route.EXPENDITURE_DETAILS_ROUTE}/:applicantNumber`,
    component: React.lazy(() => import("../pages/ExpenditureDetailsPage"))
  };

  export const EmploymentDetailsPage = {
    path:`${route.EMPLOYMENT_DETAILS_ROUTE}/:applicantNumber`,
    component: React.lazy(() => import("../pages/EmploymentDetailsPage"))
  };

  export const IncomeDetailsPage  = {
    path:`${route.INCOME_DETAILS_ROUTE}/:applicantNumber`,
    component: React.lazy(() => import("../pages/IncomeDetailsPage"))
  };

  export const AddressDetailsPage  = {
    path:`${route.ADDRESS_DETAILS_ROUTE}/:applicantNumber`,
    component: React.lazy(() => import("../pages/AddressDetailsPage"))
  };

  export const DipResultPage  = {
    path: route.DIP_RESULT_ROUTE,
    component: React.lazy(() => import("../pages/DipResultPage"))
  };

  export const ContactDetailsPage  = {
    path:`${route.CONTACT_DETAILS_ROUTE}/:applicantNumber`,
    component: React.lazy(() => import("../pages/ContactDetailsPage"))
  };

  export const BureauAuthenticationPage  = {
    path:`${route.BUREAU_AUTH_PAGE}/:questionNumber`,
    component: React.lazy(() => import("../pages/BureauAuthenticationPage"))
  };

  export const BureauFailurePage  = {
    path: route.BUREAU_FAILURE,
    component: React.lazy(() => import("../pages/BureauAuthenticationFailurePage"))
  };

  export const ErrorPage = {
    path:route.ERROR_PAGE,
    component: React.lazy(() => import("../components/ErrorPage"))
  }

  export const ErrorPageDecisionBureau = {
    path:route.ERROR_PAGE_DECISION_BUREAU,
    component: React.lazy(() => import("../components/ErrorPageDecisionBureau"))
  }

  export const TimeoutPage = {
    path:route.TIMEOUT_PAGE,
    component: React.lazy(() => import("../components/TimeoutPage"))
  }

  export const SummaryDetailsPage  = {
    path: route.SUMMARY_DETAILS,
    component: React.lazy(() => import("../pages/SummaryDetailsPage"))
  };



import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import globalState from './reducer';

export default function initStore () {

    const rootReducer = combineReducers({
        globalState
    });

    // https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
    /* istanbul ignore next */
    const composeEnhancer = (process.env.REACT_APP_DEV_MODE==='true' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    return createStore(
        rootReducer,
        composeEnhancer(applyMiddleware(thunk))
    );

}
